'use strict';

app.constant('DASHBOARDCONSTANT', {
    EVENT: {
        REFRESH_WIDGET: "REFRESH_WIDGET",
        REFRESH_QC_WIDGET: "REFRESH_QC_WIDGET",
        REFRESH_QC_GW_WIDGET: "REFRESH_QC_GW_WIDGET",
        REFRESH_DATASOURCE: "REFRESH_DATASOURCE",
        REFRESH_WIDGET_CUSTOM: "REFRESH_WIDGET_CUSTOM"
    },
    COOKIES: {
        DASHBOARD_FILTER: "DASHBOARD_FILTER",
        DASHBOARD_FILTER_QC: "DASHBOARD_FILTER_QC",
        DASHBOARD_FILTER_QF: "DASHBOARD_FILTER_QF",
        DASHBOARD_FILTER_QC_GW: "DASHBOARD_FILTER_QC_GW"
    },
    MODE: {
        BYLATEST: {
            value: "BYLATEST",
            dateFormat: "MMM/dd"
        },
        BYMTD: {
            value: "BYMTD",
            dateFormat: "MMM/dd"
        },
        BYDATERANGE: {
            value: "BYDATERANGE",
            dateFormat: "MMM/dd"
        },
        BYYTD: {
            value: "BYYTD",
            dateFormat: "MMM"
        },
        BYFYPD: {
            value: "BYFYPD",
            dateFormat:"",
        },
        BYYRMTH: {
            value: "BYYRMTH",
            dateFormat: "yyyy/MMM",
        }
    },
    BAR_COLOR: {
        FIRST: ["#770E00", "#9E1300", "#C51800", "#EC1D00", "#FF4228", "#FF654F", "#FF988A"],
        SECOND: ["#06453f", "#0a7b70", "#0FB2A2", "#13E8D3", "#22EDD9", "#58F1E2", "#8FF6EC"],
        THIRD: ["#0D4B5D", "#157491", "#1C9EC4", "#27B6E0", "#5BC8E8", "#7DD3EC", "#B0E5F4"]
    },
    DATA_STATUS: {
        LOADING: 1,
        COMPLETED_HAS_DATA: 2,
        COMPLETED_NO_DATA: 3,
        ERROR: 4
    }
});

